<template>
    <Disclosure as="nav" class="bg-pink-800" v-slot="{ open }" :key="componentKey">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="border-b border-pink-700">
                <div class="flex items-center justify-between px-4 py-3 sm:px-0">
                    <div class="shrink-0">
                        <Link :href="route('merchant_app.merchants.index')">
                            <img
                                class="w-32"
                                src="/images/membersome-logo-light.svg"
                                alt="Membersome"
                                draggable="false"
                            />
                        </Link>
                    </div>
                    <div class="hidden md:block">
                        <div class="ml-4 flex items-center md:ml-6">
                            <Link
                                :href="route('merchant_app.counter.phone')"
                                class="mr-4 inline-flex items-center rounded-md border border-transparent bg-pink-100 px-4 py-2 text-center text-sm font-medium text-pink-700 hover:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                                >Go to Counter</Link
                            >

                            <MerchantsDropdown class="mr-3" />

                            <button
                                type="button"
                                class="rounded-full bg-pink-800 p-1 text-pink-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-800"
                            >
                                <span class="sr-only">View notifications</span>
                                <BellIcon class="h-6 w-6" aria-hidden="true" />
                            </button>

                            <!-- Profile dropdown -->
                            <Menu as="div" class="relative ml-3">
                                <div>
                                    <MenuButton
                                        class="flex max-w-xs items-center rounded-full bg-pink-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-800"
                                    >
                                        <span class="sr-only">Open user menu</span>
                                        <UserIcon
                                            class="h-8 w-8 rounded-full bg-pink-200 fill-pink-700 p-1.5"
                                            aria-hidden="true"
                                        />
                                    </MenuButton>
                                </div>
                                <transition
                                    enter-active-class="transition ease-out duration-100"
                                    enter-from-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-from-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95"
                                >
                                    <MenuItems
                                        class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                        <MenuItem
                                            v-for="item in profile"
                                            :key="item.text"
                                            v-slot="{ active }"
                                        >
                                            <Link
                                                :href="route(item.route)"
                                                :class="[
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-gray-700',
                                                ]"
                                            >
                                                {{ item.text }}
                                            </Link>
                                        </MenuItem>
                                    </MenuItems>
                                </transition>
                            </Menu>
                        </div>
                    </div>
                    <div class="-mr-2 flex md:hidden">
                        <!-- Mobile menu button -->
                        <DisclosureButton
                            class="inline-flex items-center justify-center rounded-md bg-pink-800 p-2 text-pink-400 hover:bg-pink-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-800"
                        >
                            <span class="sr-only">Open main menu</span>
                            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                        </DisclosureButton>
                    </div>
                </div>
            </div>

            <div class="hidden pt-3 md:block">
                <nav class="flex flex-wrap items-baseline gap-x-4">
                    <Link
                        v-for="item in navigation.filter((item) => !item.hidden)"
                        :key="item.route"
                        :href="route(item.route)"
                        :class="[
                            route().current(item.rootRoute)
                                ? 'bg-pink-900 text-white'
                                : 'text-pink-300 hover:bg-pink-700 hover:text-white',
                            'rounded-md px-3 py-2 text-sm font-medium',
                        ]"
                        :aria-current="route().current(item.rootRoute) ? 'page' : undefined"
                        >{{ item.text }}</Link
                    >
                </nav>
            </div>
        </div>

        <DisclosurePanel class="border-b border-pink-700 md:hidden">
            <div class="space-y-1 px-2 py-3 sm:px-3">
                <Link
                    v-for="item in navigation.filter((item) => !item.hidden)"
                    :key="item.route"
                    :href="route(item.route)"
                    class="block rounded-md px-3 py-2 text-base font-medium"
                    :class="{
                        'bg-pink-900 text-white': route().current(item.rootRoute),
                        'text-pink-300 hover:bg-pink-700 hover:text-white': !route().current(
                            item.rootRoute
                        ),
                    }"
                    :aria-current="route().current(item.rootRoute) ? 'page' : undefined"
                >
                    {{ item.text }}
                </Link>

                <Link
                    :href="route('merchant_app.counter.phone')"
                    class="block rounded-md bg-pink-100 px-3 py-2 text-base font-medium text-pink-700 hover:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    >Go to Counter</Link
                >
            </div>
            <div class="border-t border-pink-700 pb-3 pt-4">
                <div class="flex items-center px-5">
                    <div class="flex-shrink-0">
                        <UserIcon
                            class="h-10 w-10 rounded-full bg-pink-200 fill-pink-700 p-1.5"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3">
                        <div class="text-base font-medium leading-none text-white">
                            {{ $page.props.merchant.name }}
                        </div>
                        <div class="text-sm font-medium leading-none text-pink-400">
                            {{ $page.props.auth.user.email }}
                        </div>
                    </div>
                    <button
                        type="button"
                        class="ml-auto flex-shrink-0 rounded-full bg-pink-800 p-1 text-pink-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-800"
                    >
                        <span class="sr-only">View notifications</span>
                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-3 space-y-1 px-2">
                    <Link
                        v-for="item in profile"
                        :key="item.text"
                        :href="route(item.route)"
                        class="block rounded-md px-3 py-2 text-base font-medium text-pink-400 hover:bg-pink-700 hover:text-white"
                    >
                        {{ item.text }}
                    </Link>
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup>
import { can } from '@/util'
import { computed, onMounted, ref } from 'vue'
import MerchantsDropdown from '@/MerchantApp/Layout/MerchantsDropdown.vue'
import { route } from '@/route'
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Link, usePage } from '@inertiajs/vue3'
import { router } from '@inertiajs/vue3'
import { UserIcon } from '@heroicons/vue/24/solid'

const merchant = computed(() => usePage().props.merchant)

const navigation = computed(() => [
    {
        text: 'Dashboard',
        route: 'merchant_app.dashboard.index',
        rootRoute: 'merchant_app.dashboard.*',
        hidden: false,
    },
    {
        text: 'Members',
        route: 'merchant_app.members.index',
        rootRoute: 'merchant_app.members.*',
        hidden: false,
    },
    {
        text: 'Point Transactions',
        route: 'merchant_app.point_transactions.index',
        rootRoute: 'merchant_app.point_transactions.*',
        hidden: false,
    },
    {
        text: 'Rewards',
        route: 'merchant_app.rewards.index',
        rootRoute: 'merchant_app.rewards.*',
        hidden: false,
    },
    {
        text: 'Campaigns',
        route: 'merchant_app.campaigns.index',
        rootRoute: 'merchant_app.campaigns.*',
        hidden: !can('manageCampaigns', merchant.value),
    },
    {
        text: 'Settings',
        route: 'merchant_app.settings.index',
        rootRoute: 'merchant_app.settings.*',
        hidden: !can('manageSettings', merchant.value),
    },
])

const profile = [
    {
        text: 'My Account',
        route: 'merchant_app.account.show',
    },
    {
        text: 'Sign out',
        route: 'merchant_app.logout.destroy',
    },
]

const open = ref(false)
const componentKey = ref(0)

onMounted(() => {
    router.on('navigate', (event) => {
        componentKey.value++
    })
})
</script>

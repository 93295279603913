<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
                class="inline-flex w-52 max-w-max flex-initial justify-center rounded-md border border-white border-opacity-30 bg-white bg-opacity-30 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            >
                <img
                    class="mr-3 h-5 w-5 rounded text-gray-400 group-hover:text-gray-500"
                    :src="currentMerchant.logo_url ?? '/images/merchant-profile-photo.svg'"
                    :alt="currentMerchant.name"
                    aria-hidden="true"
                />

                <div class="truncate">{{ currentMerchant.name }}</div>

                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-20 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <div class="py-1">
                    <MenuItem v-for="merchant in merchants" :key="merchant.id" v-slot="{ active }">
                        <Link
                            :href="route('merchant_app.dashboard.index', { merchant })"
                            :class="[
                                merchant.id === currentMerchant.id
                                    ? 'bg-pink-200 text-pink-700'
                                    : active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm',
                            ]"
                        >
                            <img
                                class="mr-3 h-5 w-5 rounded text-gray-400 group-hover:text-gray-500"
                                :src="merchant.logo_url ?? '/images/merchant-profile-photo.svg'"
                                :alt="merchant.name"
                                aria-hidden="true"
                            />

                            {{ merchant.name }}

                            <CheckCircleIcon
                                v-if="merchant.id === currentMerchant.id"
                                class="ml-2 h-4 w-4"
                                aria-hidden="true"
                            />
                        </Link>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import { computed } from 'vue'
import { Link } from '@inertiajs/vue3'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/vue/24/solid'
import { route } from '@/route'
import { usePage } from '@inertiajs/vue3'

const merchants = computed(() => usePage().props.auth.user.merchants)

const currentMerchant = computed(() => usePage().props.merchant)
</script>

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import '../css/merchant-app.css'
import '../css/flatpickr.css'
import Layout from '@/MerchantApp/Layout/Layout.vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

createInertiaApp({
    resolve(name) {
        return resolvePageComponent(
            `./MerchantApp/Pages/${name}.vue`,
            import.meta.glob('./MerchantApp/Pages/**/*.vue')
        ).then((page) => {
            page.default.layout = page.default.layout || Layout
            return page
        })
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) }).use(plugin)

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            integrations: [
                new BrowserTracing({
                    tracingOrigins: [import.meta.env.VITE_APP_DOMAINS_MERCHANT_APP, /^\//],
                }),
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
            logErrors: true,
        })

        app.mount(el)
    },
    progress: {
        color: '#f9a8d4',
    },
})

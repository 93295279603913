<template>
    <Notification
        ref="block"
        :title="notification.title"
        :body="notification.body"
        :variant="notification.variant"
    />
</template>

<script setup>
import Notification from '@/Components/Notification.vue'
import { computed, ref, watch } from 'vue'
import { usePage } from '@inertiajs/vue3'

const notification = computed(() => usePage().props.notification)
const block = ref(null)

watch(notification, (value) => {
    if (!value.title && !value.body) {
        return
    }

    block.value.open()
})
</script>

<template>
    <nav class="mx-auto flex max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
            <li>
                <div>
                    <Link
                        :href="route('merchant_app.merchants.index')"
                        class="text-pink-400 hover:text-pink-500"
                    >
                        <BuildingStorefrontIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </Link>
                </div>
            </li>
            <li v-for="(breadcrumb, breadcrumbIndex) in breadcrumbs" :key="breadcrumb.url">
                <div class="flex items-center">
                    <ChevronRightIcon
                        class="h-5 w-5 flex-shrink-0 text-pink-400"
                        aria-hidden="true"
                    />
                    <Link
                        :href="breadcrumb.url"
                        class="ml-4 text-sm font-medium text-pink-500 hover:text-pink-700"
                        :aria-current="
                            breadcrumbIndex < breadcrumbs.length - 1 ? undefined : 'page'
                        "
                        >{{ breadcrumb.title }}</Link
                    >
                </div>
            </li>
        </ol>
    </nav>
</template>

<script setup>
import { ChevronRightIcon, BuildingStorefrontIcon } from '@heroicons/vue/20/solid'
import { Link, usePage } from '@inertiajs/vue3'
import { route } from '@/route'
import { computed } from 'vue'

const breadcrumbs = computed(() => usePage().props.breadcrumbs)
</script>
